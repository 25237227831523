@import url("https://fonts.googleapis.com/css2?family=Anton&family=Poppins:wght@100;200;400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
* {
	font-family: "Poppins", "sans-serif", "Montserrat";
	/* border: 1px solid #012a4a; */
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
:root {
	--lightBlue: #62a5c2;
	--mdBlue: #2a6f98;
	--blue: #01497c;
	--purple: #cc3363;
	--green: #7acc66;
	--orange: #ff8c22;
	--yellow: #ffd700;
	--dark: #343a40;
	--black: #1a1a1a;
	--darkblue: #012a4a;
	--lightGray: #6c757d;
	--lightgrey: #cdcdcd;
}
body {
	font-family: "Poppins", "sans-serif", "Montserrat";
	--lightBlue: #62a5c2;
	--mdBlue: #2a6f98;
	--blue: #01497c;
	--purple: #cc3363;
	--green: #7acc66;
	--orange: #ff8c22;
	--yellow: #ffd700;
	--dark: #343a40;
}

/* font */

.font {
	font-family: "Poppins";
}

.font_A {
	font-family: "Anton", sans-serif;
}

/* navbar */

.logo-font {
	font-family: "Anton", sans-serif;
	letter-spacing: 3.5px;
}
.bg-blue {
	background-color: var(--blue);
	padding: 0 90px;
}

li {
	list-style-type: none;
}

.line {
	width: 2px;
	height: 20px;
	background-color: white;
}

.navbar a {
	color: white !important;
}
.navbar .dropdown-menu a {
	font-weight: 500 !important;
	font-size: 14px !important;
	line-height: 20px !important;
	letter-spacing: 0.1px !important;
	color: #012a4a !important;
}

.fsSmall {
	font-size: 12px;
}

.searchdropdowntitle {
	font-size: 22px;
	color: #2a6f98;
	font-weight: 600;
}
.searchdropdownbody {
	font-size: 14px;
	color: #6c757d;
	font-weight: 500;
}

.burger {
	display: none;
}

.left-column {
	height: 700px;
}
.left-column h2 {
	top: 40%;
}
.left-column a {
	top: 50%;
}
.search-bar {
	height: 60px;
	overflow: hidden;
}
.sort button {
	height: 60px;
	width: fit-content;
}

@media (max-width: 1024px) {
	.webNav {
		display: none;
	}
	.burger {
		display: flex;
	}
	.left-column {
		height: 400px;
	}
	.left-column h2 {
		top: 40%;
	}
	.left-column a {
		top: 55%;
		text-decoration: none;
	}
	.banner {
		display: none;
	}
	.search-bar {
		border-radius: 7px !important;
		height: 40px;
	}
	.search-bar input {
		font-size: 13px !important;
	}
	.sort {
		width: 30% !important;
	}
	.sort button {
		padding: 0.5rem;
		height: 40px;
		font-size: 13px !important;
	}

	.order {
		width: 900px !important;
	}

	.smallFont {
		font-size: 14px;
	}
}

/* footer */
.footer {
	width: 100%;
	color: black;
}
.nav-link {
	width: fit-content;
}

/*  body */

.children {
	min-height: 70vh;
}

.swiper-slide {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100% !important;
}
.swiper-button-disabled {
	display: none;
}

/* .my-custom-pagination-div {
		position: absolute;
		display: flex;
		padding-bottom: 1.5rem;
		padding-right: 2rem;
		justify-content: end;
		z-index: 3;
		list-style: none !important;
	} */
.my-custom-pagination-div span {
	color: transparent;
}
.swiper-pagination-bullet {
	background: #fff !important ;
	opacity: 1;
	width: 10px;
	height: 10px;
}
.swiper-pagination-bullet-active {
	background: var(--blue) !important ;
}
.pagePrev {
	filter: opacity(0.5);
	cursor: default !important;
}
.pageActive {
	background-color: var(--blue) !important;
	color: #cdcdcd !important;
}